.header {
  font-family: "Poppins";
  font-weight: 500;
  color: #000;
}
.navLink {
  padding: 0 30px;
}

.navLink a {
  color: #000 !important;
}
@media only screen and (max-width: 991px){
  .ToggleBtn{
    border: none;
    color: rgb(255, 255, 255) !important;
  }
  
  .menuBg{
    background-color: rgb(241 101 42) !important;
  }
}
