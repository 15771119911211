.nav-tabs {
  background-color: #dfe6e9;
}

.nav-tabs a.active {
  color: #000 !important;
  border: none;
}

.nav-tabs a {
  font-size: 14px;
  padding: 29px 44px;
  color: #636e72;
}

.nav-tabs a:hover {
  color: #636e72;
}

.ContainerTabs {
  position: relative;
  z-index: 100;
  margin-top: -80px;
}

.spanTabTitle {
  position: relative;
  top: 2px;
}

.tab-pane {
  padding: 50px;
}

.tab-pane h4.tabTitleContent {
  font-family: "Poppins";
  font-weight: 700;
  float: left;
  padding-right: 20px;
}

.tab-pane p.tabPriceContent {
  float: left;
  position: relative;
  top: 4px;
  font-family: "Poppins";
  font-weight: 700;
}

.tab-pane p.tabPriceContent span{
  /* color: rgb(63, 208, 212); */
  color: rgb(240, 101, 36);
}

.tab-pane p.tabPriceContent small {
  color: #636e72;
  padding-left: 10px;
  position: relative;
}

.tab-pane p.tabDescriptionContent {
  font-family: "Poppins";
}

.tab-pane div.tabInformationContentTitle {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
}

.tab-pane div.tabInformationContentValue {
  font-family: "Poppins";
  font-size: 14px;
}

.galrieTitre {
  font-family: "Poppins";
  font-weight: 700;
}

.tabTitleProgramme {
  font-family: "Poppins";
  font-weight: 700;
}

.nbrJour {
  background-color: #0d6aaf;
  text-align: center;
  padding: 10px;
  color: #fff;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  position: relative;
  display: inline-block;
  width: 100%;
}

.jourTitle {
  padding-top: 10px;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20px;
}

span.separator {
  height: 100%;
  position: absolute;
  z-index: -1;
  width: 50px;
  left: 50%;
  transform: translate(-50%);
}

span.separator span.separatorContent {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 0;
  height: 100%;
  border-right: 2px dashed #0d6aaf;
  z-index: 8;
  content: "";
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tabDescriptionReserver {
  font-family: "Poppins";
  font-weight: 400;
}

.calendarYearMonthDiv {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  background: #0d6aaf;
  color: #fff;
}

.calendarYearMonthDiv .calendarYearMonthLabel {
  font-family: "Poppins";
  font-weight: 700;
}

.table thead th {
  border: none !important;
}

.table td,
.table th {
  border: none !important;
}

.hasTarif span {
  padding: 5px 10px;
  background-color: #0d6aaf;
  border-radius: 50%;
  color: #fff;
}

.calendar-day {
  font-family: "Poppins";
  font-weight: 400;
}

.hasTarif span {
  cursor: pointer;
}


.readMoreDiv{
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 43%);
}

.readMoreSpan{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  border: 2px solid #fff;
  padding: 15px;
  font-family: Poppins;
  font-weight: 700;
}

.linkReadMore:hover .readMoreDiv{
  display: block;
}

.mobileSpan{
  color:rgb(240, 101, 36);
  font-family: Poppins;
  font-weight: 700;
  padding-right: 15px;
  display: none;
}

@media only screen and (max-width: 767px){
  .mobileSpan{
    display: block !important;
  }

  .webSpace{
    display: none !important;
  }
}

.reserveButton{
  border: 1px solid rgb(240, 101, 36) !important;
  color: rgb(240, 101, 36) !important;
  background-color: transparent !important;
  font-family: Poppins;
  font-weight: 700;
}

.reserveButton:hover{
  border: 1px solid rgb(240, 101, 36) !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(240, 101, 36) !important;
}

.reserveEduisee{
  border: 1px solid rgb(255, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(255, 0, 0) !important;
  font-family: Poppins;
  font-weight: 700;
  cursor: auto !important;
}

.reserveButton:focus {
  box-shadow: 0 0 0 0.2rem rgb(240 101 36) !important;
}

.reserveEduisee:focus{
  box-shadow: 0 0 0 0.2rem rgb(255, 0, 0) !important;
}

.selectCustom:focus{
  box-shadow: 0 0 0 0.2rem rgb(240 101 36/ 25%) !important;
  border-color: rgb(240, 101, 36) !important;
}

.selectCustom{
  color: rgb(240, 101, 36) !important;
  font-family: Poppins;
  font-weight: 700;
}

.btn-valider{
  background-color: transparent !important;
  color: rgb(240 101 36) !important;
  font-family: Poppins !important;
  font-weight: 700 !important;
  border-color: rgb(240 101 36) !important;
  width: 40% !important;
}

.btn-valider:hover{
  background-color: rgb(240 101 36) !important;
  color: rgb(255, 255, 255) !important;
}

.btn-valider:focus{
  box-shadow: 0 0 0 0.2rem rgb(240 101 36/ 25%) !important;
  border-color: rgb(240, 101, 36) !important;
}

.textMontantTotal{
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 20px;
}

.montantValue{
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 20px;
  color: rgb(240, 101, 36) !important;
}